<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" :inline="true">
            <el-form-item label="渠道" prop="channelNo" label-width="120px">
                <el-select clearable v-model="queryParams.channelNo" placeholder="渠道"  size="small" >
                    <el-option v-for="item in acqChannelList" :label="item.name" :value="item.channelNo"
                        :key="item.channelNo"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="活动政策" prop="activityNo" label-width="120px">
                <el-select style="width:240px" v-model="queryParams.activityNo" size="small" clearable
                    placeholder="请选择活动政策">

                    <el-option v-for="item in activities" :label="item.activityName" :value="item.activityNo"
                        :key="item.activityNo"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="代理商编号" prop="agentNo" label-width="120px">
                <el-input v-model="queryParams.agentNo" clearable placeholder="请输入代理商编号" style="width: 240px"
                    size="small" />
            </el-form-item>
        </el-form>
        <!-- v-if="agentLevel == 1 || agentLevel==0" -->
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" v-permission="'ADJUST_TASK_CONFIG_APPLY_QUERY'" icon="el-icon-search"
                    size="mini" @click="handleQuery"  >
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery"  >
                    重置
                </el-button>
                <el-button type="primary" v-permission="'ADJUST_TASK_CONFIG_APPLY_UPDATE'" icon="el-icon-plus"
                    size="mini" @click="newRequest"  >
                    新增调价申请
                </el-button>
            </el-col>
        </el-row>

        <div>
            <el-table stripe border v-loading="loading" :data="dataList">
                <el-table-column label="渠道" prop="channelName" :show-overflow-tooltip="true">

                </el-table-column>
                <el-table-column label="活动政策" prop="activityName" :show-overflow-tooltip="true" />
                <el-table-column label="代理商" prop="agentNo" :show-overflow-tooltip="true" />
                <el-table-column label="调价类型" prop="adjustNo">
                    <template slot-scope="scope">
                        {{ scope.row.adjustNo | adjustTypeFM }}
                    </template>
                </el-table-column>
                <el-table-column label="调价比例" prop="adjustRate" :show-overflow-tooltip="true" />
                <el-table-column label="调价策略" prop="adjustStrategy" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{ scope.row.adjustStrategy | adjustStrategyFM }}
                        {{ scope.row.strategyDays }}天起调
                    </template>
                </el-table-column>
                <el-table-column label="分成比例" prop="profitProportion" :show-overflow-tooltip="true" >
                    <template slot-scope="scope" v-if="scope.row.profitProportion>0">
                        {{ scope.row.profitProportion}}%
                    </template>
                </el-table-column>
                <el-table-column label="申请时间" prop="createTime" :show-overflow-tooltip="true" width="160" />
                <el-table-column label="审核状态" prop="auditStatus" :show-overflow-tooltip="true">

                    <template slot-scope="scope">
                        {{ scope.row.auditStatus | adjustAuditStatusFM }}
                    </template>
                </el-table-column>
                <el-table-column label="申请备注" prop="agentRemark" :show-overflow-tooltip="true" />
                <el-table-column label="审核备注" prop="auditRemark" :show-overflow-tooltip="true" />
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180"
                    fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" v-if="scope.row.auditStatus == 'WAITING'"
                            @click="handleDelete(scope.row)">删除</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.auditStatus == 'FAILED' 
                            && userInfo.orgAgentNo == scope.row.agentNo" @click="handleEdit(scope.row,1)">修改</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.auditStatus == 'WAITING' 
                            && userInfo.orgAgentNo != scope.row.agentNo" @click="handleEdit(scope.row,2)">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize"
                @pagination="getList" />
        </div>

        <el-dialog :title="title" :visible.sync="open" width="520px">
            <el-form ref="saveOrEditForm" :model="saveData" :rules="rules" label-width="120px">
                <el-form-item :label="'代理商编号'" prop="agentNo">
                    <el-input v-model="saveData.agentNo" size="mini" style="width: 200px;" @change="agentNoChange" @input="agentNoInput" :disabled="showType==2" />
                </el-form-item>
                <el-form-item label="渠道" prop="channelNo">
                    <el-select v-model="saveData.channelNo" placeholder="渠道" @change="channelNoChange"  size="mini" style="width: 200px;" :disabled="showType==2">
                        <el-option v-for="item in channelList" :label="item.name" :value="item.channelNo"
                            :key="item.channelNo"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="活动政策" prop="activityNo">
                    <el-select v-model="saveData.activityNo" placeholder="活动政策"  size="mini" style="width: 200px;" :disabled="showType==2">
                        <el-option v-for="item in originActivities" :label="item.activityName" :value="item.activityNo"
                            :key="item.activityNo"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="加收类型" prop="channelNo">
                    <el-select @change="adjustNoChange" v-model="saveData.adjustNo" placeholder="请选择加收类型" clearable
                        size="small" style="width: 200px;" :disabled="showType==2">
                        <el-option v-for="(value, key) in dicts.ADJUST_NO" :label="value" :value="key" :key="key">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="调价比例" prop="adjustRate">
                    <el-select v-model="saveData.adjustRate" placeholder="请选择调价比例"  size="mini" style="width: 200px;" :disabled="showType==2">
                        <el-option v-for="item in rateList" :label="item.label" :value="item.value" :key="item.value">
                        </el-option>
                    </el-select> <span v-if="saveData.adjustNo == 'B'">元</span><span v-else>%</span>
                </el-form-item>
                <el-form-item label="调价策略" prop="adjustStrategy">
                    <el-select v-model="saveData.adjustStrategy" placeholder="调价策略" size="mini" style="width: 200px" :disabled="showType==2">
                        <el-option v-for="(value, key) in dicts.ADJUST_STRATEGY" :label="value" :value="key" :key="key">
                        </el-option>
                    </el-select>
                    <el-input-number placeholder="请输入天数" v-model="saveData.strategyDays" size="mini"  style="width: 100px;" :min="1"
                        :max="1000" :disabled="showType==2"></el-input-number>
                    <label style="font-weight: normal;">天起调</label>
                </el-form-item>
                <el-form-item label="备注" prop="agentRemark" v-if="showType==1">
                    <el-input type="textarea" v-model="saveData.agentRemark"></el-input>
                </el-form-item>
                <el-form-item label="代理商备注" prop="agentRemark" v-if="showType==2">
                    <el-input type="textarea" v-model="saveData.agentRemark" disabled></el-input>
                </el-form-item>
                <el-form-item label="分成比例" prop="profitProportion" v-if="showType==2">
                    <el-input placeholder="请输入分成比例(0-100)" size="mini" v-model="saveData.profitProportion"
                        style="width:200px;margin-right: 4px;" /><span>%</span>
                </el-form-item>
                <el-form-item label="审核备注" v-if="showType==2">
                    <el-input type="textarea" v-model="saveData.auditRemark"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm" v-if="showType==1">{{ translate('FROM.SUBMIT') }}</el-button>
                <el-button type="primary" @click="examineForm" v-if="showType==2">审核通过</el-button>
                <el-button type="primary" @click="reject" v-if="showType==2">驳回</el-button>
                <el-button type="primary" @click="cancel">{{ translate('FROM.CANCEL') }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { MerchantApi, TerminalApi, AgentApi } from '@/api'
import { mapState } from 'vuex'
export default {

    data() {
        return {
            saveData: {
                adjustRate: '',
                adjustStrategy: '',
                agentNo: '',
                channelNo: '',
                activityNo: '',
                strategyDays: 0,
                agentRemark:''
            },
            rules: {
                adjustRate: [{ required: true, message: '请输入调价比例', trigger: 'change' }],
                adjustStrategy: [{ required: true, message: '请选择调价策略', trigger: 'change' }],
                agentNo: [{ required: true, message: '请输入代理商编号', trigger: 'change' }],
                channelNo: [{ required: true, message: '请选择渠道', trigger: 'change' }],
                activityNo: [{ required: true, message: '请选择活动政策', trigger: 'change' }],
                strategyDays: [{ required: true, message: '请输入比例', trigger: 'change' }]
            },
            loading: false,
            title: '',
            open: false,
            acqChannelList: [],
            channelList: [],
            activities: [],
            originActivities: [],
            total: 0,
            pageNo: 1,
            pageSize: 10,
            dataList: [],
            rateList: [],
            queryParams: {

            },
            dicts: {
                ADJUST_NO: {
                    "A": "调价A",
                    "C": "调价C"
                },
                ADJUST_STRATEGY: {
                    "MERCHANT_REGISTER_STANDARD": "从商户入网开始满"
                }
            },
            showType: 1
        }
    },
    components: {
    },
    created() {
        this.getChannelList();
        this.getActivities();
    },
    computed: {
        ...mapState('app', ['userInfo']),
        agentLevel() {
            return this.userInfo.loginAgent.agentLevel
        },
    },
    methods: {
        handleQuery() {
            let that = this;
            MerchantApi.adjustTaskConfigApply.list(this.pageNo, this.pageSize, this.queryParams).then(response => {
                if (response.success) {
                    response.data.forEach(item => {
                        item.channelName = this.findChannelName(item.channelNo);
                        item.activityName = this.findActivityName(item.activityNo);
                    });
                    this.dataList = response.data;
                    this.total=response.count;
                }
            }); 
        },
        agentNoChange(val){
            if(val){
                AgentApi.agentDetail(val).then(res => {
                    if (res.data) {
                        this.channelList = res.data.channelList;
                    }else{
                        this.channelList = []
                    }
                });
            }else{
                this.channelList = []
            }
        },
        agentNoInput(){
            this.saveData.channelNo = '';
            this.saveData.activityNo = '';
            this.originActivities = []
        },
        handleResetQuery() {
            this.queryParams.agentNo = '';
            this.queryParams.activityNo = '';
            this.queryParams.channelNo = '';
        },
        handleRateChange() {

        },
        findChannelName(channelNo) {
            var channel = this.acqChannelList.find(item => item.channelNo == channelNo);
            if (channel) return channel.name;
            return '';
        },
        findActivityName(activityNo) {
            var activity = this.activities.find(item => item.activityNo == activityNo);
            if (activity) return activity.activityName;
            return '';
        },
        handleEdit(row,type) {
            this.adjustNoChange(row.adjustNo);
            this.saveData=JSON.parse(JSON.stringify(row));
            this.showType = type;
            this.open = true; 
        },
        handleDelete(row) {
            this.$confirm("是否确定删除?",
                "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let result = await MerchantApi.adjustTaskConfigApply.remove(row.id);
                if (result.success) {
                    this.Message.success("删除成功");
                    
                    this.getList();
                }
                this.init();
            });
        },
        async getChannelList() {
            const result = await TerminalApi.acqChannel.listAllChannel();
            this.acqChannelList = result && result.data;
        },
        getActivities() {
            TerminalApi.queryInfo.getActivityInfo().then(response => {
                this.activities = response.data;
            });
        },
        getList() {
            this.handleQuery();
        },
        init(){
            this.saveData = {
                        adjustRate: '',
                        adjustStrategy: '',
                        agentNo: '',
                        channelNo: '',
                        activityNo: '',
                        strategyDays: 0,
                        agentRemark: ''
                    };
        },
        submitForm() {
            this.$refs['saveOrEditForm'].validate((valid) => {
                if (valid) { 
                    if(this.saveData.id){
                        MerchantApi.adjustTaskConfigApply.edit(this.saveData.id,this.saveData).then(response => {
                        if (response.success) {
                            this.Message.success("成功"); 
                            this.getList();
                            this.open=false;
                            this.init();
                        }
                    });
                    }else{
                        MerchantApi.adjustTaskConfigApply.save(this.saveData).then(response => {
                        if (response.success) {
                            this.Message.success("成功");
                            this.getList();
                            this.open=false;
                            this.init();
                        }
                    });
                    }


                } else {
                    return false;
                }
            });
        },
        examineForm(){
            if (!this.saveData.profitProportion) {
                this.Message.error('请输入分成比例');
                return;
            }
            MerchantApi.adjustTaskConfigApply.audit(this.saveData.id,{
                auditRemark:this.saveData.auditRemark,
                auditStatus:'SUCCESS',
                profitProportion:this.saveData.profitProportion
            }).then(response => {
                if (response.success) {
                    this.Message.success("成功");
                    this.getList();
                    this.open=false;
                }
            });
        },
        async reject() {
            if (!this.saveData.auditRemark) {
                this.Message.error('请在审核备注一栏输入驳回的原因');
                return;
            }
            let result = await MerchantApi.adjustTaskConfigApply.audit(this.saveData.id, {
                auditStatus: 'FAILED',
                auditRemark: this.saveData.auditRemark
            });
            if (result.success) {
                this.getList();
                this.open = false;
            }
        },
        newRequest() {
            this.title = '发起调价申请';
            this.showType = 1;
            this.init();
            this.open = true;
        },
        cancel() {
            this.open = false;
        },
        adjustNoChange(val) {
            this.rateList = [];
            console.log(val);
            this.saveData.adjustRate = '';
            this.saveData.adjustType = 'TRANS';
            if (val == 'C') {
                for (let i = 53; i <= 100; i++) {
                    this.rateList.push({ value: i / 100, label: i / 100 })
                }
            } else {
                for (let i = 0; i <= 20; i++) {
                    this.rateList.push({ value: i / 100, label: i / 100 })
                }
            }
        },
        channelNoChange(val) {
            this.saveData.activityNo = ''
            TerminalApi.queryInfo.queryByChannelNo(val).then(response => {
                this.originActivities = response.data;
            });
        }
    }
}
</script>